
import { gql } from "@apollo/client";

export const SEARCH_PRODUCT_VARIANT = gql`
    query SearchVariant($organizationId: String!, $searchProductVariantInput: SearchInputV2!) {
        searchProductVariants(organizationId: $organizationId, searchProductVariantInput: $searchProductVariantInput) {
            hits {
                objectID
                productId
                variantTitle
                sku
                model
                warningTags {
                    name
                    level
                }
                errorTags {
                    name
                    level
                }
                normalTags {
                    name
                    level
                }
                barcode
                price
                vatRate
                reference
                availableQuantity
                reservedQuantity
                physicalQuantity
                status
                images {
                    name
                    downloadUrl
                }
                stockReferences {
                    id
                    warehouseReference
                    locationName
                    locationId
                    physicalQuantity
                    availableQuantity
                    reservedQuantity
                }
            }
            nbHits
            nbPages
            facets
            query
            params
        }
    }
`