import { gql } from "@apollo/client";

export const SEARCH_ORDERS = gql`
  query SearchOrders(
    $organizationId: String!
    $searchOrderInput: SearchInputV2!
  ) {
    searchOrdersV2(
      organizationId: $organizationId
      searchOrderInput: $searchOrderInput
    ) {
      hits {
        _tags
        objectID
        orderId
        externalReference
        invoiceNumber
        issuedAt
        tpAppId
        tpAppName
        cmsIdentifier
        cmsName
        cmsLogoUrl
        shippingMethodName
        lines {
          productTitle
          quantity
          sku
          variantId
        }
        deliveryOrders {
          requestedShippingMethod
          locationId
          locationName
          lastEvent
          lastEventLevel
          orderNumber
        }
        deliveryAddress {
          fullname
          email
          address
          addressComplement
          zipcode
          country
          city
          state
          phone
        }
        state
        normalTags {
          name
          level
        }
        warningTags {
          name
          level
        }
        errorTags {
          name
          level
        }
        shipments {
          lastEvent
          tracking
          usedShippingMethod
          trackingUrl
        }
      }
      facets
      nbHits
      nbPages
      exhaustiveNbHits
      query
      params
    }
  }
`;
