import { gql } from "@apollo/client";

export const SEARCH_RMA_ORDERS = gql`
  query SearchRmaOrders(
    $organizationId: String!
    $searchRmaOrderInput: SearchInputV2!
  ) {
    searchRmaOrders(
      organizationId: $organizationId
      searchRmaOrderInput: $searchRmaOrderInput
    ) {
      hits {
        objectID
        returnReference
        returnNumber
        orders {
          invoiceNumber
        }
        sender {
          fullname
        }
        externalReference
        expectedResolution
        processingStatus
        deliveryStatus
        financialStatus
        fulfillmentStatus
        receivedAt
        hasErrors
        hasFinancialErrors
        needsModeration
        normalTags {
            name
        }
        warningTags {
            name
        }
        errorTags {
            name
        }
        shipments {
          tracking
        }
        tpAppId
      }
      facets
      nbHits
      nbPages
      exhaustiveNbHits
      query
      params
    }
  }
`;
