import { Button, FooterHelp } from "@shopify/polaris"
import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import useTranslator from "../hooks/i18n.hook"

enum FooterEnum {
  DASHBOARD = 'DASHBOARD',
  ORDERS = 'ORDERS',
  PRODUCTS = 'PRODUCTS',
  TRANSFER_ORDERS = 'TRANSFER_ORDERS',
  RMA_ORDERS = 'RMA_ORDERS',
  SETTINGS = 'SETTINGS',
  NULL = "NULL"
}

function Footer() {
  const i18n = useTranslator("Footer")
  const router = useRouter()
  const [category, setCategory] = useState<FooterEnum>(FooterEnum.NULL)

  useEffect(() => {
    setCategory(getFooterCategory())
  }, [])


  const getFooterCategory = useCallback(() => {
    if (router.pathname === '/') {
      return FooterEnum.DASHBOARD
    } else if (router.pathname.includes('transfer')) {
      return FooterEnum.TRANSFER_ORDERS
    } else if (router.pathname.includes('orders')) {
      return FooterEnum.ORDERS
    } else if (router.pathname.includes('products')) {
      return FooterEnum.PRODUCTS
    } else if (router.pathname.includes('returns')) {
      return FooterEnum.RMA_ORDERS
    } else if (router.pathname.includes('settings')) {
      return FooterEnum.SETTINGS
    } else {
      return FooterEnum.NULL;
    }
  }, [router])

  return category !== FooterEnum.NULL ?
    <FooterHelp>
      {i18n.translate(category, { scope: "Footer.paragraph" })}
      <Button
        plain
        onClick={() => window.open(i18n.translate(category, { scope: "Footer.linkValue" }))}>{i18n.translate(category, { scope: "Footer.linkLabel" })}</Button>
    </FooterHelp>
    : <></>
}


export default Footer