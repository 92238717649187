import {
  ActionList,
  Card,
  Frame,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TopBar,
  Loading,
  Stack,
  Popover,
  Avatar,
  Spinner,
  TextStyle,
  Tabs,
  ResourceList,
  ResourceItem,
  Badge,
  Link,
  Thumbnail,
  ButtonGroup,
  Button,
} from "@shopify/polaris";
import {
  ExistingInventoryMajor,
  LogOutMinor,
  OrdersMajor,
  ProductsMajor,
  ReturnMinor,
} from "@shopify/polaris-icons";
import { useRouter } from "next/router";
import { MutableRefObject, useCallback, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  useApplicationContext,
  useApplicationDispatchContext,
} from "../contexts/application.context";
import {
  useAuthContext,
  useAuthDispatchContext,
} from "../contexts/auth.context";
import useTranslator from "../hooks/i18n.hook";
import { SEARCH_PRODUCT_VARIANT } from "../queries/product/search-product.query";
import { SearchVariant } from "../queries/product/__generated__/SearchVariant";
import { SEARCH_ORDERS } from "../queries/search-orders.query";
import { SEARCH_RMA_ORDERS } from "../queries/search-rma-order.query";
import { SearchOrders } from "../queries/__generated__/SearchOrders";
import { SearchRmaOrders } from "../queries/__generated__/SearchRmaOrders";
import ApplicationNavigation from "./navigation";
import OrganizationMenu from "./organization-menu";
import ChangePassword from "./user/change-password";
import Footer from "./footer";
import { SearchTransferOrders } from "../queries/__generated__/SearchTransferOrders";
import { SEARCH_TRANSFER_ORDERS } from "../queries/search-transfer-orders.query";

enum EntityType {
  ORDERS = "ORDERS",
  PRODUCTS = "PRODUCTS",
  TRANSFER_ORDERS = "TRANSFER_ORDERS",
  RMA_ORDERS = "RMA_ORDERS",
}

export type ApplicationFrameProps = {
  children: any;
  skipToContentRef?: MutableRefObject<any>;
  isLoading?: boolean;
};

const HITS_PER_PAGE = 5;

const MAX_LENGTH_TITLE = 50;

function ApplicationFrame(props: ApplicationFrameProps) {
  const { isLoading, skipToContentRef } = props;
  const { organizationId, language } = useApplicationContext();
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const i18n = useTranslator("ApplicationFrame");
  const { user } = useAuthContext();
  const applicationDispatcher = useApplicationDispatchContext();
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const router = useRouter();

  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const handleLanguage = useCallback(
    (selectedLangage) => {
      applicationDispatcher({
        type: "LANGUAGE_SELECTED",
        payload: {
          language: selectedLangage,
        },
      });
      router.reload();
    },
    [applicationDispatcher]
  );

  const { loading: loadingOrders, data: orders } = useQuery<SearchOrders>(
    SEARCH_ORDERS,
    {
      variables: {
        organizationId,
        searchOrderInput: {
          page: 0,
          hitsPerPage: HITS_PER_PAGE,
          query: searchValue
            ? searchValue?.split(" ")?.length > 1
              ? searchValue?.split(" ").join("* ") + "*"
              : searchValue + "*"
            : "",
        },
      },
      skip: !organizationId || !searchValue,
    }
  );

  const { loading: loadingVariants, data: variants } = useQuery<SearchVariant>(
    SEARCH_PRODUCT_VARIANT,
    {
      variables: {
        organizationId,
        searchProductVariantInput: {
          page: 0,
          hitsPerPage: HITS_PER_PAGE,
          query: searchValue
            ? searchValue?.split(" ")?.length > 1
              ? searchValue?.split(" ").join("* ") + "*"
              : searchValue + "*"
            : "",
        },
      },
      skip: !organizationId || !searchValue,
    }
  );

  const { loading: loadingTransferOrders, data: transferOrders } =
    useQuery<SearchTransferOrders>(SEARCH_TRANSFER_ORDERS, {
      variables: {
        organizationId,
        searchTransferOrderInput: {
          page: 0,
          hitsPerPage: HITS_PER_PAGE,
          query: searchValue
            ? searchValue?.split(" ")?.length > 1
              ? searchValue?.split(" ").join("* ") + "*"
              : searchValue + "*"
            : "",
        },
      },
      skip: !organizationId || !searchValue,
    });

  const { loading: loadingRmaOrders, data: rmaOrders } =
    useQuery<SearchRmaOrders>(SEARCH_RMA_ORDERS, {
      variables: {
        organizationId,
        searchRmaOrderInput: {
          page: 0,
          hitsPerPage: HITS_PER_PAGE,
          query: searchValue
            ? searchValue?.split(" ")?.length > 1
              ? searchValue?.split(" ").join("* ") + "*"
              : searchValue + "*"
            : "",
        },
      },
      skip: !organizationId || !searchValue,
    });

  const searchResults = {
    orders: orders?.searchOrdersV2?.hits || [],
    variants: variants?.searchProductVariants?.hits || [],
    transferOrders: transferOrders?.searchTransferOrders?.hits || [],
    rmaOrders: rmaOrders?.searchRmaOrders?.hits || [],
  };

  const searchResultsSections = [
    ...(searchResults.orders?.length
      ? [
        {
          title: EntityType.ORDERS,
          items: searchResults.orders?.map((o) => ({
            number: o.invoiceNumber,
            subTitle: i18n.translate(
              "Frame.globalSearch.OrderRessourceItem.subTitle",
              { tpApp: o.tpAppName }
            ),
            thirdTitle: i18n.translate(
              "Frame.globalSearch.OrderRessourceItem.thirdTitle",
              {
                fullname: o.deliveryAddress.fullname,
                date: i18n.formatDate(new Date(Number(o.issuedAt))),
              }
            ),
            primaryLine: `${o.deliveryOrders
              .map((dO) => dO.orderNumber)
              .join("; ")}`,
            secondaryLine: `${o.shipments.map((s) => s.tracking).join("; ")}`,
            secondTag: null,
            tag: o._tags[0],
            url: `/orders/${o.objectID}`,
          })),
        },
      ]
      : []),
    ...(searchResults.variants?.length
      ? [
        {
          title: EntityType.PRODUCTS,
          items: searchResults.variants?.map((o) => ({
            number: o.variantTitle,
            subTitle: null,
            thirdTitle: null,
            primaryLine: i18n.translate(
              "Frame.globalSearch.ProductRessourceItem.primaryLine",
              { sku: o.sku }
            ),
            secondaryLine: i18n.translate(
              "Frame.globalSearch.ProductRessourceItem.secondaryLine",
              { ean: o.barcode }
            ),
            secondTag: o.model,
            tag: o.status,
            url: `/products/${o.objectID}`,
          })),
        },
      ]
      : []),
    ...(searchResults.transferOrders?.length
      ? [
        {
          title: EntityType.TRANSFER_ORDERS,
          items: searchResults.transferOrders?.map((o) => ({
            number: o.orderNumber,
            subTitle: i18n.translate(
              "Frame.globalSearch.TransferOrderRessourceItem.subTitle",
              { date: i18n.formatDate(new Date(Number(o.expectedDate))) }
            ),
            thirdTitle: null,
            primaryLine: i18n.translate(
              "Frame.globalSearch.TransferOrderRessourceItem.primaryLine",
              {
                containerNumber: o.containerNumber,
                containerType: o.containerType,
                expectedQuantity: o.totalExpectedQuantity,
                referenceQuantity: o.lines.length,
              }
            ),
            secondaryLine: o.tracking,
            secondTag: null,
            tag: o.normalTags[0]?.name,
            url: `/transfer-orders/${o.objectID}`,
          })),
        },
      ]
      : []),
    ...(searchResults.rmaOrders?.length
      ? [
        {
          title: EntityType.RMA_ORDERS,
          items: searchResults.rmaOrders?.map((o) => ({
            number: o.returnReference,
            subTitle: i18n.translate(
              "Frame.globalSearch.RmaOrderRessourceItem.subTitle",
              { orderNumber: o.orders.map((o) => o.invoiceNumber).join("/") }
            ),
            thirdTitle: null,
            primaryLine: i18n.translate(
              "Frame.globalSearch.RmaOrderRessourceItem.primaryLine",
              { fullname: o.sender.fullname }
            ),
            secondaryLine: `${o.shipments.map((s) => s.tracking).join(", ")}`,
            secondTag: null,
            tag: o.processingStatus,
            url: `/returns/${o.objectID}`,
          })),
        },
      ]
      : []),
  ];

  const { isLoggedIn } = useAuthContext();
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue("");
  }, []);

  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const dispatcher = useAuthDispatchContext();
  const [changePasswordModalActive, setChangePasswordModalActive] =
    useState(false);
  const toggleChangePasswordModal = useCallback(
    () => setChangePasswordModalActive(!changePasswordModalActive),
    [changePasswordModalActive]
  );

  const isSearchLoading = useCallback(() => {
    return (
      loadingOrders ||
      loadingVariants ||
      loadingTransferOrders ||
      loadingRmaOrders
    );
  }, [loadingOrders, loadingVariants, loadingTransferOrders, loadingRmaOrders]);

  const tabs = [
    {
      id: "all",
      content: i18n.translate("ALL", { scope: "Frame.globalSearch.tabs" }),
      accessibilityLabel: "all",
      panelID: "all",
    },
    {
      id: "order",
      content: i18n.translate(EntityType.ORDERS, {
        scope: "Frame.globalSearch.tabs",
      }),
      panelID: "orders",
    },
    {
      id: "catalog",
      content: i18n.translate(EntityType.PRODUCTS, {
        scope: "Frame.globalSearch.tabs",
      }),
      panelID: "catalog",
    },
    {
      id: "transfer-orders",
      content: i18n.translate(EntityType.TRANSFER_ORDERS, {
        scope: "Frame.globalSearch.tabs",
      }),
      panelID: "transfer-orders",
    },
    {
      id: "rma-orders",
      content: i18n.translate(EntityType.RMA_ORDERS, {
        scope: "Frame.globalSearch.tabs",
      }),
      panelID: "rma-orders",
    },
  ];

  const getPath = useCallback(
    (title) => {
      switch (title) {
        case EntityType.ORDERS:
          return `/orders?query=${searchValue}`;
        case EntityType.PRODUCTS:
          return `/products?query=${searchValue}`;
        case EntityType.TRANSFER_ORDERS:
          return `/transfer-orders?query=${searchValue}`;
        case EntityType.RMA_ORDERS:
          return `/returns?query=${searchValue}`;
      }
    },
    [searchValue]
  );

  const getMediaIcon = useCallback((title) => {
    switch (title) {
      case EntityType.ORDERS:
        return <Thumbnail source={OrdersMajor} alt={title}></Thumbnail>;
      case EntityType.PRODUCTS:
        return <Thumbnail source={ProductsMajor} alt={title}></Thumbnail>;
      case EntityType.TRANSFER_ORDERS:
        return (
          <Thumbnail source={ExistingInventoryMajor} alt={title}></Thumbnail>
        );
      case EntityType.RMA_ORDERS:
        return <Thumbnail source={ReturnMinor} alt={title}></Thumbnail>;
    }
  }, []);

  const getShowAll = useCallback((title) => {
    switch (title) {
      case EntityType.ORDERS:
        return i18n.translate(EntityType.ORDERS, {
          scope: "Frame.globalSearch.showAll",
        });
      case EntityType.PRODUCTS:
        return i18n.translate(EntityType.PRODUCTS, {
          scope: "Frame.globalSearch.showAll",
        });
      case EntityType.TRANSFER_ORDERS:
        return i18n.translate(EntityType.TRANSFER_ORDERS, {
          scope: "Frame.globalSearch.showAll",
        });
      case EntityType.RMA_ORDERS:
        return i18n.translate(EntityType.RMA_ORDERS, {
          scope: "Frame.globalSearch.showAll",
        });
    }
  }, []);

  const getTagStatus = useCallback((status) => {
    switch (status) {
      case "DRAFT":
        return;
      case "CANCELED":
        return "warning";
      case "DISPATCHING_ERROR":
        return "critical";
      case "FULFILLMENT_IN_PROGRESS":
        return "info";
      case "FULFILLMENT_NOT_REQUESTED":
        return "warning";
      case "FULFILLMENT_WARNING":
        return "warning";
      case "COMPLETED":
        return "success";
      case "OPENED":
        return "info";
      case "NEW":
        return "new";
      case "ACCEPTED":
        return "info";
      case "ANOMALY":
        return "critical";
      case "COMPLETE":
        return "success";
      case "DELETED":
        return;
      case "ON_HOLD":
        return "warning";
      case "PROCESSING":
        return "info";
      case "REQUESTED":
        return "info";
      case "ACTIVE":
        return "success";
      case "ARCHIVED":
        return "warning";
      case "DISABLED":
        return "critical";
    }
  }, []);

  const ressourceListItemMarkup = useCallback(
    (item, result, index) => (
      <ResourceItem
        id={`${index}-${result.title}`}
        url={""}
        media={getMediaIcon(result.title)}
        onClick={() => {
          /*window.open(window?.location?.origin + item.url)*/
        }}
      >
        <Stack alignment="center">
          <Stack.Item fill>
            <TextContainer>
              <Link url={item.url} removeUnderline monochrome>
                {item.number && truncate(item.number, MAX_LENGTH_TITLE)}{" "}
                {item.subTitle && (
                  <span style={{ fontSize: "11px" }}>{item.subTitle}</span>
                )}
              </Link>
              <br />
              {item.thirdTitle && (
                <>
                  <Link url={item.url} removeUnderline monochrome>
                    <span style={{ fontSize: "11px" }}>{item.thirdTitle}</span>
                  </Link>
                  <br />
                </>
              )}
              <span style={{ fontSize: "11px" }}>
                <Link url={item.url} removeUnderline monochrome>
                  {item.primaryLine}
                </Link>
              </span>
              <br />
              <span style={{ fontSize: "11px" }}>
                <Link url={item.url} removeUnderline monochrome>
                  {item.secondaryLine}
                </Link>
              </span>
            </TextContainer>
          </Stack.Item>
          {(item.tag || item.secondTag) && (
            <Stack.Item>
              <Stack vertical spacing="extraTight">
                {item.secondTag && (
                  <Stack.Item>
                    <Badge>
                      {i18n.translate(item.secondTag, {
                        scope: "Frame.globalSearch.model",
                      })}
                    </Badge>
                  </Stack.Item>
                )}
                {item.tag && (
                  <Stack.Item>
                    <Badge status={getTagStatus(item.tag)}>
                      {i18n.translate(item.tag, {
                        scope: "Frame.globalSearch.status",
                      })}
                    </Badge>
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
          )}
        </Stack>
      </ResourceItem>
    ),
    []
  );

  const allTabMarkup = (
    <div style={{ overflowY: "scroll", maxHeight: "55vh" }}>
      {searchResultsSections.map((r, index) => (
        <Card.Section
          key={`${r.title}-${index}`}
          title={i18n.translate(r.title, { scope: "Frame.globalSearch.tabs" })}
        >
          <ResourceList
            resourceName={{ singular: r.title, plural: r.title + "s" }}
            items={r.items.slice(0, 3).map((i) => i)}
            renderItem={(i, index) => {
              return ressourceListItemMarkup(i, r, index);
            }}
          />
          <Stack distribution="trailing">
            <Stack.Item>
              <Link url={getPath(r.title)}>
                <span style={{ fontSize: "11px" }}>{getShowAll(r.title)}</span>
              </Link>
            </Stack.Item>
          </Stack>
        </Card.Section>
      ))}
    </div>
  );

  const tabMarkup = useCallback(
    (type) =>
      searchResultsSections.filter((r) => r.title === type).length ? (
        <div style={{ overflowY: "scroll", maxHeight: "55vh" }}>
          {searchResultsSections
            .filter((r) => r.title === type)
            .map((r, index) => (
              <Card.Section
                key={`${r.title}-${index}`}
                title={i18n.translate(r.title, {
                  scope: "Frame.globalSearch.tabs",
                })}
              >
                <ResourceList
                  resourceName={{ singular: r.title, plural: r.title + "s" }}
                  items={r.items.map((i) => i)}
                  renderItem={(i, index) => {
                    return ressourceListItemMarkup(i, r, index);
                  }}
                />
                <Stack distribution="trailing">
                  <Stack.Item>
                    <Link url={getPath(r.title)}>
                      <span style={{ fontSize: "11px" }}>
                        {getShowAll(r.title)}
                      </span>
                    </Link>
                  </Stack.Item>
                </Stack>
              </Card.Section>
            ))}
        </div>
      ) : (
        <Card>
          <Card.Section>
            <Stack distribution="center">
              <Stack.Item>
                {i18n.translate("Frame.globalSearch.noResult")}
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card>
      ),
    [searchResultsSections]
  );

  const searchResultsMarkup = isSearchLoading() ? (
    <Card sectioned>
      <Stack distribution="center" alignment="center" vertical>
        <Stack.Item>
          <Spinner></Spinner>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="subdued">
            {i18n.translate("Frame.globalSearch.searching")}
          </TextStyle>
        </Stack.Item>
      </Stack>
    </Card>
  ) : searchResultsSections.length ? (
    <Card>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
        {selected === 0 && allTabMarkup}
        {selected === 1 && tabMarkup(EntityType.ORDERS)}
        {selected === 2 && tabMarkup(EntityType.PRODUCTS)}
        {selected === 3 && tabMarkup(EntityType.TRANSFER_ORDERS)}
        {selected === 4 && tabMarkup(EntityType.RMA_ORDERS)}
      </Tabs>
    </Card>
  ) : (
    <Card>
      <Card.Section>
        <Stack distribution="center">
          <Stack.Item>
            {i18n.translate("Frame.globalSearch.noResult")}
          </Stack.Item>
        </Stack>
      </Card.Section>
    </Card>
  );

  const searchFieldMarkup = isLoggedIn ? (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  ) : null;

  const { organizationName } = useApplicationContext();
  const [userMenuActive, setUserMenuActive] = useState(false);
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );

  // Organization selector
  const organizationSelector = isLoggedIn ? <OrganizationMenu /> : null;
  const userMenuActivatorMarkup = isLoggedIn ? (
    <>
      <span
        className={"Polaris-TopBar-UserMenu__Details"}
        style={{ marginRight: "10px" }}
      >
        <p className={"Polaris-TopBar-UserMenu__Detail"}>
          {i18n.translate("Frame.organizationSelector")}
        </p>
        <p className={"Polaris-TopBar-UserMenu__Name"}>{organizationName}</p>
      </span>
      <Avatar
        size="small"
        source={undefined}
        initials={user?.email?.charAt(0)?.toUpperCase()}
      />
    </>
  ) : null;

  const userMenuMarkup = isLoggedIn ? (
    <TopBar.Menu
      activatorContent={userMenuActivatorMarkup}
      open={userMenuActive}
      onOpen={toggleUserMenuActive}
      onClose={toggleUserMenuActive}
      actions={[]}
    />
  ) : null;

  const userMenuPopoverMarkup = isLoggedIn ? (
    <Popover
      active={userMenuActive}
      activator={userMenuMarkup}
      autofocusTarget="first-node"
      onClose={toggleUserMenuActive}
      fixed
    >
      {user ? (
        <Popover.Pane fixed>
          <Popover.Section>
            <div style={{ width: "220px" }}>
              <Stack vertical alignment="center">
                <Stack.Item>
                  <Avatar initials={user?.email?.charAt(0).toUpperCase()} />
                </Stack.Item>
                <Stack.Item>{user?.email}</Stack.Item>
              </Stack>
            </div>
          </Popover.Section>
        </Popover.Pane>
      ) : null}
      <Popover.Pane>
        <div style={{ marginTop: "10px" }}>
          <Stack distribution="center">
            <Stack.Item>
              <ButtonGroup segmented>
                <Button
                  size="slim"
                  pressed={language === "fr"}
                  onClick={() => handleLanguage("fr")}
                >
                  {i18n.translate("Frame.selectedLangage.french")} 🇫🇷
                </Button>
                <Button
                  size="slim"
                  pressed={language === "en"}
                  onClick={() => handleLanguage("en")}
                >
                  {i18n.translate("Frame.selectedLangage.english")} 🇬🇧
                </Button>
              </ButtonGroup>
            </Stack.Item>
          </Stack>
        </div>
        <ActionList
          actionRole="menuitem"
          items={[
            {
              content: i18n.translate(
                "Frame.userMenu.actionList.updatePassword"
              ),
              onAction: () => setChangePasswordModalActive(true),
            },
            {
              content: i18n.translate("Frame.userMenu.actionList.logout"),
              icon: LogOutMinor,
              onAction: () => {
                router.push("/login");
                dispatcher({ type: "LOGOUT" });
              },
            },
          ]}
        />
      </Popover.Pane>
    </Popover>
  ) : null;

  const topBarMarkup = isLoggedIn ? (
    <TopBar
      showNavigationToggle
      contextControl={organizationSelector}
      userMenu={userMenuPopoverMarkup}
      searchResultsVisible={searchActive}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  ) : null;

  const loadingMarkup = isLoading ? <Loading /> : null;

  const navigationMarkup = isLoggedIn ? <ApplicationNavigation /> : null;
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const changePasswordMarkup = isLoggedIn ? (
    <ChangePassword
      onClose={toggleChangePasswordModal}
      active={changePasswordModalActive}
    />
  ) : null;

  const pageMarkup = isLoading ? loadingPageMarkup : props.children;
  const logo = {
    width: 124,
    topBarSource:
      "https://happycolis.com/wp-content/uploads/2020/12/logo-HC-transp.png",
    url: "https://happycolis.com",
    accessibilityLabel: "HappyColis",
  };

  return (
    <Frame
      topBar={topBarMarkup}
      navigation={navigationMarkup}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    //logo={logo}
    >
      {loadingMarkup}
      {pageMarkup}
      <Footer></Footer>
      {changePasswordMarkup}
    </Frame>
  );
}
export default ApplicationFrame;

function truncate(value: string, length: number) {
  return value.length >= length - 3
    ? value.slice(0, length - 3) + "..."
    : value;
}
