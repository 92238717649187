import { gql } from "@apollo/client";

export const LOGIN_ORGANIZATION_MUTATION = gql`
  mutation LoginOrganization($organizationId: String!, $clientId: String!) {
    loginOrganization(
      organizationId: $organizationId
      clientId: $clientId
    ) {
        accessToken
        refreshToken
    }
  }
`;

export const ME_QUERY = gql`
  query WhoAmI {
    whoAmI {
        id
        username
        email
        description
    }
  }
`;
