import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  Form,
  FormLayout,
  Layout,
  Banner,
  Modal,
  Toast,
} from "@shopify/polaris";
import { useField, useForm } from "@shopify/react-form";
import validator from "validator";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { ChangePassword as ChangePasswordInterface } from "./__generated__/ChangePassword";
import useTranslator from "../../hooks/i18n.hook";

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export type ChangePasswordType = {
  active: boolean;
  onClose: () => void;
};

function ChangePassword(props: ChangePasswordType) {

  const i18n = useTranslator("ChangePasswordModal")

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>();
  const [toastError, setToastError] = useState(false);

  const handleToast = useCallback((toastInterface: { active: boolean, message: string, title?: string, error?: boolean }) => {
    setToastActive(toastInterface.active);
    setToastMessage(toastInterface.message);
    setToastError(toastInterface.error || false);
  }, [toastActive, toastMessage, toastError])

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} error={toastError} onDismiss={() => setToastActive(false)} />
  ) : null;

  const [changePasswordMutation] = useMutation<ChangePasswordInterface>(
    CHANGE_PASSWORD_MUTATION
  );

  const passwordField = useField<string>({
    value: "",
    validates: [
      (value) =>
        !value || validator.isEmpty(value)
          ? i18n.translate("ChangePassword.Form.currentPassword.empty")
          : undefined,
    ],
  });

  const confirmPasswordField = useField({
    value: "",
    validates: [
      (value) =>
        !value || validator.isEmpty(value)
          ? i18n.translate("ChangePassword.Form.confirmation.empty")
          : undefined,
      (value) =>
        !value || !passwordField.value || value !== passwordField.value
          ? i18n.translate("ChangePassword.Form.confirmation.invalid")
          : undefined,
    ],
  });

  const {
    fields: { oldPassword, password, passwordConfirm },
    submit,
    submitting,
    dirty,
    reset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: {
      oldPassword: useField({
        value: "",
        validates: [
          (value) =>
            !value || validator.isEmpty(value)
              ? i18n.translate("ChangePasswordModal.error")
              : undefined,
        ],
      }),
      password: passwordField,
      passwordConfirm: confirmPasswordField,
    },
    onSubmit: async (fieldValues) => {
      let result;

      try {
        result = await changePasswordMutation({
          variables: {
            oldPassword: fieldValues.oldPassword,
            newPassword: fieldValues.password,
          },
        });
      } catch (error) {
        handleToast({ message: i18n.translate("ChangePasswordModal.error"), error: true, active: true });
      }

      if (result?.data?.changePassword === true) {
        props.onClose();
        return { status: "success" };
      }

      return {
        status: "fail",
        errors: [{ message: i18n.translate("ChangePasswordModal.error") }],
      };
    },
  });

  const handleCancel = useCallback(() => {
    reset();
    props.onClose();
  }, [props, reset]);

  const loginErrorMarkup =
    submitErrors.length > 0 ? (
      <Layout.Section>
        <Banner status="critical">
          {i18n.translate("ChangePasswordModal.error")}
        </Banner>
      </Layout.Section>
    ) : null;

  return (
    <Modal
      open={props.active}
      onClose={handleCancel}
      title={i18n.translate("ChangePasswordModal.title")}
      loading={submitting}
      primaryAction={{
        content: i18n.translate("ChangePasswordModal.actions.primary"),
        onAction: submit,
        disabled: !dirty,
      }}
      secondaryActions={[
        {
          content: i18n.translate("ChangePasswordModal.actions.secondary"),
          onAction: handleCancel,
        },
      ]}
    >
      <Modal.Section>
        <Layout>
          {loginErrorMarkup}
          <Layout.Section>
            <Form onSubmit={submit}>
              <FormLayout>
                <TextField
                  label={i18n.translate("ChangePasswordModal.Form.currentPassword.label")}
                  type="password"
                  {...oldPassword}
                  autoComplete="off"
                />
                <TextField
                  label={i18n.translate("ChangePasswordModal.Form.new.label")}
                  type="password"
                  {...password}
                  autoComplete="off"
                />
                <TextField
                  label={i18n.translate("ChangePasswordModal.Form.confirmation.label")}
                  type="password"
                  {...passwordConfirm}
                  autoComplete="off"
                />
              </FormLayout>
            </Form>
          </Layout.Section>
        </Layout>
      </Modal.Section>
    </Modal>
  );
}
export default ChangePassword;
