import React from "react";
import { Navigation } from "@shopify/polaris";
import {
  ProductsMajor,
  OrdersMajor,
  HomeMajor,
  SettingsMajor,
  ExistingInventoryMajor,
  ReturnMinor,
} from "@shopify/polaris-icons";
import { useAuthContext } from "../contexts/auth.context";
import { useRouter } from "next/router";
import useTranslator from "../hooks/i18n.hook";

export default function ApplicationNavigation() {
  const i18n = useTranslator("Navigation")
  const { isLoggedIn } = useAuthContext();
  const router = useRouter();
  return isLoggedIn ? (
    <Navigation location={router.pathname}>
      <Navigation.Section
        fill
        items={[
          {
            url: '/',
            label: i18n.translate("Navigation.items.dashboard.label"),
            icon: HomeMajor,
            selected: router.pathname === '/',
          },
          {
            url: '/orders',
            label: i18n.translate("Navigation.items.orders.label"),
            icon: OrdersMajor,
            selected: router.pathname.split("/")[1] === 'orders',
            exactMatch: true,
            onClick: () => router.push('/orders'),
            subNavigationItems: [
              {
                url: '/orders/new',
                label: i18n.translate("Navigation.items.orders.actions.createOrder"),
                exactMatch: true,
              }
            ],
          },
          {
            url: '/products',
            label: i18n.translate("Navigation.items.products.label"),
            icon: ProductsMajor,
            selected: router.pathname.split("/")[1] === 'products',
            exactMatch: true,
            onClick: () => router.push('/products'),
            subNavigationItems: [
              {
                url: '/products/new',
                label: i18n.translate("Navigation.items.products.actions.createProduct")
              },
              {
                url: '/products/bundle/new',
                label: i18n.translate("Navigation.items.products.actions.createBundle"),
                exactMatch: true,
              }
            ],
          },
          {
            url: '/transfer-orders',
            label: i18n.translate("Navigation.items.transferOrders.label"),
            icon: ExistingInventoryMajor,
            selected: router.pathname.split("/")[1] === 'transfer-orders',
            onClick: () => router.push('/transfer-orders'),
            subNavigationItems: [
              {
                url: '/transfer-orders/new',
                label: i18n.translate("Navigation.items.transferOrders.actions.createTransferOrder")
              },
            ],
          },
          {
            url: '/returns',
            label: i18n.translate("Navigation.items.returns.label"),
            icon: ReturnMinor,
            selected: router.pathname.split("/")[1] === 'returns',
            onClick: () => router.push('/returns'),
            subNavigationItems: [
              {
                url: '/returns/new',
                label: i18n.translate("Navigation.items.returns.actions.createReturn")
              },
            ],
          }
        ]}
      />
      <Navigation.Section
        items={[{
          url: '/settings',
          label: i18n.translate("Navigation.items.settings.label"),
          icon: SettingsMajor,
          selected: router.pathname.split("/")[1] === 'settings',
        },
        ]}
      />
    </Navigation>
  ) : null;
}

