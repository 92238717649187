import { gql } from "@apollo/client";

export const SEARCH_TRANSFER_ORDERS = gql`
  query SearchTransferOrders(
    $organizationId: String!
    $searchTransferOrderInput: SearchInputV2!
  ) {
    searchTransferOrders(
      organizationId: $organizationId
      searchTransferOrderInput: $searchTransferOrderInput
    ) {
      hits {
        objectID
        externalReference
        orderNumber
        carrier
        shippingDate
        expectedDate
        issuedAt
        createdAt
        updatedAt
        tracking
        state
        hasErrors
        hasWarnings
        containerType
        containerNumber
        lines {
          label
        }
        normalTags {
          name
          level
        }
        warningTags {
          name
          level
        }
        errorTags {
          name
          level
        }
        locationName
        locationId
        supplierName
        supplierId
        totalReceivedQuantity
        totalExpectedQuantity
        hasReceptionWarnings
      }
      facets
      nbHits
      nbPages
      exhaustiveNbHits
      query
      params
    }
  }
`;
