import { useMutation } from "@apollo/client";
import {
  ActionList,
  Button,
  Image,
  Popover,
  TextField,
  UnstyledLink,
} from "@shopify/polaris";
import { AppExtensionMinor } from "@shopify/polaris-icons";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import {
  useApplicationContext,
  useApplicationDispatchContext,
} from "../contexts/application.context";
import { useAuthDispatchContext } from "../contexts/auth.context";
import useTranslator from "../hooks/i18n.hook";
import { LOGIN_ORGANIZATION_MUTATION } from "../queries/login-organization.mutation";
import { LoginOrganization } from "../queries/__generated__/LoginOrganization";

function OrganizationMenu() {
  const i18n = useTranslator("OrganizationMenu");
  const { memberships } = useApplicationContext();
  const applicationDispatcher = useApplicationDispatchContext();
  const dispatch = useAuthDispatchContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();
  const [organizationStartWith, setOrganizationStartWith] = useState("");
  const [focus, setFocus] = useState(false);

  const [loginOrganization] = useMutation<LoginOrganization>(
    LOGIN_ORGANIZATION_MUTATION
  );

  useEffect(() => {
    if (isMenuOpen) {
      setTimeout(() => {
        setFocus(true);
      }, 0);
    } else {
      setTimeout(() => {
        setFocus(false);
      }, 0);
    }
  }, [isMenuOpen, focus]);

  const toggleIsMenuOpen = useCallback(() => {
    setIsMenuOpen((isSecondaryMenuOpen) => !isSecondaryMenuOpen);
  }, []);

  const handleChangeOrganizationStartWith = useCallback((value) => {
    setOrganizationStartWith(value);
  }, []);

  const handleChangeOrganization = useCallback(
    async (id, name, slug) => {
      setOrganizationStartWith("");
      const login = await loginOrganization({
        variables: {
          organizationId: id,
          clientId: process.env.NEXT_PUBLIC_CLIENT_ID,
        },
      });

      if (!login.data?.loginOrganization) {
        dispatch({
          type: "LOGOUT",
          payload: {},
        });
        router.push("/login");
        return;
      }

      const accessToken = login.data?.loginOrganization;
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          accessToken: accessToken.accessToken,
          refreshToken: accessToken.refreshToken,
        },
      });

      applicationDispatcher({
        type: "ORGANIZATION_SELECTED",
        payload: {
          organizationId: id,
          organizationName: name,
          organizationSlug: slug,
        },
      });
      toggleIsMenuOpen();
      router.push("/");
    },
    [
      applicationDispatcher,
      dispatch,
      loginOrganization,
      router,
      toggleIsMenuOpen,
    ]
  );
  const items =
    [
      {
        title: "",
        items: memberships
          ? [
              ...memberships
                ?.filter((m) =>
                  m.name
                    .toLowerCase()
                    .startsWith(organizationStartWith.toLowerCase())
                )
                .filter((m) => m["isActive"])
                .map((o) => ({
                  content: o.name,
                  onAction: () =>
                    handleChangeOrganization(o.id, o.name, o.slug),
                })),
            ]
          : [],
      },
      {
        title: i18n.translate("OrganizationMenu.inactives"),
        items: memberships
          ? [
              ...memberships
                ?.filter((m) =>
                  m.name
                    .toLowerCase()
                    .startsWith(organizationStartWith.toLowerCase())
                )
                .filter((m) => !m["isActive"])
                .map((o) => ({
                  content: o.name,
                  onAction: () =>
                    handleChangeOrganization(o.id, o.name, o.slug),
                })),
            ]
          : [],
      },
    ] || [];

  const buttonStyle = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "20px",
    height: "20px",
    display: "block",
    border: "none",
    cursor: "pointer",
    transition: ".2s all ease",
    color: "#bf0711",
  };

  const activator =
    memberships?.length > 1 ? (
      <span style={buttonStyle}>
        <Button
          onClick={toggleIsMenuOpen}
          icon={AppExtensionMinor}
          plain
          monochrome
        ></Button>
      </span>
    ) : (
      <p></p>
    );

  const logoMarkup = (
    <UnstyledLink
      url="/"
      style={{ width: "150px" }}
      className="Polaris-TopBar__LogoLink"
    >
      <Image
        source="https://happycolis.com/wp-content/uploads/2020/12/logo-HC-transp.png"
        alt="HappyColis"
        style={{ width: "150px" }}
        className="Polaris-TopBar__Logo"
      />
    </UnstyledLink>
  );

  return (
    <div className="Polaris-TopBar__LogoContainer Polaris-TopBar__LogoDisplayControl">
      <Popover
        fluidContent={true}
        active={isMenuOpen}
        activator={activator}
        onClose={toggleIsMenuOpen}
      >
        <Popover.Pane>
          <div
            style={{
              marginLeft: "10px",
              marginTop: "10px",
              marginRight: "10px",
              marginBottom:
                items?.at(0)?.items.length + items?.at(1)?.items.length > 0
                  ? "0px"
                  : "10px",
            }}
          >
            <TextField
              label=""
              autoFocus={focus}
              focused={focus}
              labelHidden
              placeholder={i18n.translate("OrganizationMenu.search")}
              value={organizationStartWith}
              onChange={handleChangeOrganizationStartWith}
              autoComplete="off"
            />
          </div>
          <ActionList sections={items} />
        </Popover.Pane>
      </Popover>
      &nbsp;
      {logoMarkup}
    </div>
  );
}
export default OrganizationMenu;
